import React, { useEffect, useContext, useState } from "react";
import { motion, useIsPresent } from "framer-motion";
import "../styles/success.css";
import { IoCheckmarkCircle, IoCloseCircle } from "react-icons/io5";
import { CartContext } from "../helpers/CartContext";
import { saveOrder } from "../helpers/firestore";
import { useNavigate } from "react-router";
import { LineWave } from "react-loader-spinner";

export const SuccessPage = () => {
  const isPresent = useIsPresent();
  const url_string = window.location.href;
  const url = new URL(url_string);
  const redirectStatus = url.searchParams.get("redirect_status");
  const paymentIntentId = url.searchParams.get("payment_intent");
  const [orderProcessed, setOrderProcessed] = useState(false);
  const { cart, clearCart } = useContext(CartContext);
  const navigate = useNavigate();

  //check if the status of the payment is actually legit
  const fetchPaymentStatus = async () => {
    try {
      const response = await fetch(
        "https://naimun-payment-server-970946723811.us-east4.run.app/get-payment-status",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ paymentId: paymentIntentId }),
        }
      );
      const { status } = await response.json();
      console.log(status);
      if (status === "succeeded") {
        saveOrder(cart);
        clearCart();
        setOrderProcessed(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (redirectStatus === "succeeded") {
      fetchPaymentStatus();
    } else if (!redirectStatus) {
      navigate("/");
    }
  }, []);

  return (
    <div className="success">
      {redirectStatus === "failed" ? (
        <>
          <IoCloseCircle fontSize={"8rem"} />
          <h1>We couldn't process your order</h1>
          <p>
            There was an error processing your order. Please try again or
            contact support.
          </p>
        </>
      ) : orderProcessed === true ? (
        <>
          <IoCheckmarkCircle fontSize={"8rem"} />
          <h1>We've recieved your order!</h1>
          <p>
            You can pickup your order during the conference using the code we
            sent to your email.
          </p>
        </>
      ) : (
        <LineWave color="#46a0d7" />
      )}
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 1 : 0 }}
        className="privacy-screen"
      />
    </div>
  );
};
