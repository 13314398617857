import React from "react";
import { motion } from "framer-motion";
import "../styles/orderDetails.css";

export default function OrderDetailsCard({ order }) {
  const date = new Date(order.date.seconds * 1000);
  const dateOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const quantity = order.items.reduce((acc, item) => acc + item.quantity, 0);
  console.log(order);

  return (
    <motion.div
      className="orderDetailsCard"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="orderCardDetailsBody">
        <div className="orderCardHeader">
          <div className="orderCardDetails">
            Order placed {date.toLocaleDateString("en-US", dateOptions)}
          </div>
        </div>
      </div>
      {order.items.map((item) => (
        <div className="orderCardLineItem">
          <div className="orderDetailsCardImage">
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Fthumbnails%2F${item.product.image}_200x200?alt=media`}
              alt={item.product.name}
            />
            <div className="orderDetailsCardText orderDetailsCardSize">
              {item.size}
            </div>
          </div>
          <div className="orderDetailsCardText">${item.product.price}</div>
          <div className="orderDetailsCardText">x{item.quantity}</div>
          <div className="orderDetailsCardText" style={{ fontWeight: "bold" }}>
            {" "}
            ${item.quantity * item.product.price}
          </div>
        </div>
      ))}
      <div className="orderCardTotalLine">
        <div className="orderDetailsCardText orderCardLineTitle">Total</div>
        <div className="orderDetailsCardText">{"    "} </div>
        <div className="orderDetailsCardText">{quantity}</div>
        <div className="orderDetailsCardText" style={{ fontWeight: "bold" }}>
          ${order.total}
        </div>
      </div>
    </motion.div>
  );
}
