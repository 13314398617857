import React, { useCallback, useState, useEffect } from "react";
import { DotButton, useDotButton } from "./carouselDots";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";
import { useNavigate } from "react-router-dom";
import "../styles/carousel.css";
import { getFeaturedProducts } from "../helpers/firestore";

const Carousel = (props) => {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [Autoplay()]);
  const navigate = useNavigate();
  const [featuredProducts, setFeaturedProducts] = useState([
    {
      id: "",
      image:
        "https://images.squarespace-cdn.com/content/v1/5ada446c70e80229f1aec2d9/2e5500da-ff5a-4fa4-86de-67ec7d8f3d3f/NAIMUN+LXII+White+Logo+Transparent+Background.png?format=1500w",
    },
    {
      id: "",
      image:
        "https://images.squarespace-cdn.com/content/v1/5ada446c70e80229f1aec2d9/2e5500da-ff5a-4fa4-86de-67ec7d8f3d3f/NAIMUN+LXII+White+Logo+Transparent+Background.png?format=1500w",
    },
    {
      id: "",
      image:
        "https://images.squarespace-cdn.com/content/v1/5ada446c70e80229f1aec2d9/2e5500da-ff5a-4fa4-86de-67ec7d8f3d3f/NAIMUN+LXII+White+Logo+Transparent+Background.png?format=1500w",
    },
    {
      id: "",
      image:
        "https://images.squarespace-cdn.com/content/v1/5ada446c70e80229f1aec2d9/2e5500da-ff5a-4fa4-86de-67ec7d8f3d3f/NAIMUN+LXII+White+Logo+Transparent+Background.png?format=1500w",
    },
    {
      id: "",
      image:
        "https://images.squarespace-cdn.com/content/v1/5ada446c70e80229f1aec2d9/2e5500da-ff5a-4fa4-86de-67ec7d8f3d3f/NAIMUN+LXII+White+Logo+Transparent+Background.png?format=1500w",
    },
  ]);

  useEffect(() => {
    getFeaturedProducts().then((data) => {
      setFeaturedProducts(data);
    });
  }, [slides]);

  const onNavButtonClick = useCallback((emblaApi) => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;

    const resetOrStop =
      autoplay.options.stopOnInteraction === false
        ? autoplay.reset
        : autoplay.stop;

    resetOrStop();
  }, []);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi,
    onNavButtonClick
  );

  return (
    <section className="embla">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((index) => (
            <div
              className="embla__slide"
              key={index}
              onClick={() => navigate(`/product/${featuredProducts[index].id}`)}
            >
              <div className="embla__slide__number">
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Ffull%2F${featuredProducts[index].image}_1728x2160?alt=media`}
                  alt=""
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="embla__controls">
        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={"embla__dot".concat(
                index === selectedIndex ? " embla__dot--selected" : ""
              )}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Carousel;
