import React, { useState, useEffect } from "react";
import "../styles/browse.css";
import { AnimatePresence, motion, useIsPresent } from "framer-motion";
import {
  IoChevronDown,
  IoChevronUp,
  IoCheckmark,
  IoFilter,
} from "react-icons/io5";
import { ProductCard } from "../components/productCard";
import { getProducts } from "../helpers/firestore";
import ReactSlider from "react-slider";
import { useNavigate } from "react-router-dom";
import { LineWave } from "react-loader-spinner";

function BrowsePage() {
  const isPresent = useIsPresent();
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [productsRaw, setProductsRaw] = useState([]);
  const [color, setColor] = useState(null);
  const [price, setPrice] = useState(null);
  const [showColor, setShowColor] = useState(false);
  const [showPrice, setShowPrice] = useState(false);
  const [category, setCategory] = useState("All");
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(true);

  const colorChoices = [
    {
      name: "Black",
      value: "#000000",
      border: "1px solid #000000",
      color: "#ffffff",
    },
    {
      name: "White",
      value: "#FFFFFF",
      border: "1px solid #444444",
      color: "#000000",
    },
    {
      name: "Navy",
      value: "#000080",
      border: "1px solid #000080",
      color: "#ffffff",
    },
    {
      name: "Off-White",
      value: "#F5F5DC",
      border: "1px solid #F5F5DC",
      color: "#000000",
    },
    {
      name: "Light Blue",
      value: "#8EC8EC",
      border: "1px solid #8EC8EC",
      color: "#ffffff",
    },
    {
      name: "Other",
      value: "#8d429f",
      border: "1px solid #444444",
      color: "#ffffff",
    },
  ];

  useEffect(() => {
    //on render, create two lists containing all items
    getProducts().then((data) => {
      setProducts(data);
      //productsRaw will never be modified, only products should be
      setProductsRaw(data);
      //disable loading animation
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    setProducts(
      productsRaw
        .filter(
          (product) =>
            product.category.toLowerCase() === category.toLowerCase() ||
            category === "All"
        )
        .filter(
          (product) =>
            color === null ||
            product.color.toLowerCase() === color.toLowerCase()
        )
        .filter(
          (product) =>
            price === null ||
            (product.price >= price[0] && product.price <= price[1])
        )
    );
  }, [category, price, color, productsRaw]);

  return (
    <>
      <div className="browsePage">
        <button
          className="filterToggle"
          onClick={() => setShowFilters(!showFilters)}
        >
          {" "}
          <IoFilter /> Filter
        </button>
        <div className={`filters ${showFilters && "filtersActive"}`}>
          <div className="filter-category">
            <div
              className={`filter ${category === "All" ? "selectedFilter" : ""}`}
              onClick={() => setCategory("All")}
            >
              All
            </div>
            <div
              className={`filter ${
                category === "Hats" ? "selectedFilter" : ""
              }`}
              onClick={() => setCategory("Hats")}
            >
              Hats
            </div>
            <div
              className={`filter ${
                category === "Shirts" ? "selectedFilter" : ""
              }`}
              onClick={() => setCategory("Shirts")}
            >
              Shirts
            </div>
            <div
              className={`filter ${
                category === "Stickers" ? "selectedFilter" : ""
              }`}
              onClick={() => setCategory("Stickers")}
            >
              Stickers
            </div>
            <div
              className={`filter ${
                category === "Accessories" ? "selectedFilter" : ""
              }`}
              onClick={() => setCategory("Accessories")}
            >
              Accessories
            </div>
          </div>
          <div className="filterCategory">
            <div className="filter" onClick={() => setShowColor(!showColor)}>
              Color {showColor ? <IoChevronUp /> : <IoChevronDown />}
            </div>
            {showColor && (
              <div className="colorChoicesRow">
                {colorChoices.map((colorChoice) => (
                  <div
                    key={colorChoice.name}
                    className="colorChoice"
                    onClick={() => {
                      setColor(
                        color === colorChoice.name ? null : colorChoice.name
                      );
                    }}
                  >
                    <div
                      className="colorChoiceCircle"
                      style={{
                        backgroundColor: colorChoice.value,
                        border: colorChoice.border,
                        color: colorChoice.color,
                      }}
                    >
                      {color === colorChoice.name && <IoCheckmark />}
                    </div>
                    {colorChoice.name}
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className="filterCategory">
            <div className="filter" onClick={() => setShowPrice(!showPrice)}>
              Price {showPrice ? <IoChevronUp /> : <IoChevronDown />}
            </div>
            {showPrice && (
              <div className="priceRanges">
                <ReactSlider
                  min={0}
                  max={50}
                  step={5}
                  defaultValue={[0, 50]}
                  minDistance={5}
                  ariaLabel="Price Slider"
                  ariaValuetext={(state) => `Price Range: ${state.valueNow}`}
                  onAfterChange={(value) => setPrice(value)}
                  pearling
                  renderThumb={(props, state) => (
                    <div {...props}>${state.valueNow}</div>
                  )}
                  className="slider"
                  thumbClassName="thumb"
                  trackClassName="track"
                />
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <LineWave
            color="#46a0d7"
            width={200}
            height={200}
            wrapperStyle={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          />
        ) : products.length === 0 ? (
          <div style={{ margin: "auto" }}>
            We can't seem find anything. Have you tried updating your filters?
          </div>
        ) : (
          <motion.div
            layout
            animate={{
              start: { opacity: 0 },
              end: { opacity: 1 },
              transition: { duration: 0.5 },
            }}
            className="products"
          >
            <AnimatePresence>
              {products.map((product) => (
                <motion.div
                  key={product.id}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  delay={0.5}
                  layout="position"
                  className="productCard"
                  onClick={() => navigate(`/product/${product.id}`)}
                >
                  <ProductCard product={product} />
                </motion.div>
              ))}
            </AnimatePresence>
          </motion.div>
        )}
      </div>
      <motion.div
        initial={{ scaleX: 1 }}
        animate={{ scaleX: 0, transition: { duration: 0.5, ease: "circOut" } }}
        exit={{ scaleX: 1, transition: { duration: 0.5, ease: "circIn" } }}
        style={{ originX: isPresent ? 1 : 0 }}
        className="privacy-screen"
      />
    </>
  );
}

export default BrowsePage;
