import {
  getFirestore,
  collection,
  doc,
  getDoc,
  getDocs,
  addDoc,
  setDoc,
  increment,
  limit,
  deleteDoc,
} from "firebase/firestore";
import { app } from "./firebase";
import { auth } from "./auth";

const db = getFirestore(app);

export const addUser = async (id, email) => {
  await setDoc(doc(db, "users", id), {
    email: email,
    orders: [],
    cart: [],
  });
};

export const getOrder = async (id) => {
  try {
    const order = await getDoc(doc(db, "orders", id));
    if (order.exists()) {
      return { id: order.id, ...order.data() };
    }
  } catch (error) {
    console.error(error);
    return error;
  }
  return null;
};

export const getOrders = async (ids) => {
  let ordersArray = [];
  for (let id of ids) {
    try {
      const order = await getOrder(id);
      ordersArray.push(order);
      //sort the orders by date descending
      ordersArray.sort((a, b) => b.date - a.date);
    } catch (error) {
      console.error(error);
    }
  }
  return ordersArray;
};

export const getProduct = async (id) => {
  const product = await getDoc(doc(db, "products", id));
  if (product.exists()) {
    const data = product.data();
    const productData = { id: product.id, ...data };
    return productData;
  } else {
    return null;
  }
};

export const getProducts = async () => {
  let productsArray = [];
  const snapshot = await getDocs(collection(db, "products"));
  snapshot.forEach((doc) => {
    const data = doc.data();
    const product = { id: doc.id, ...data };
    productsArray.push(product);
  });
  return productsArray;
};

export const getUser = async (id) => {
  const user = await getDoc(doc(db, "users", id));
  if (user.exists()) {
    return user.data();
  } else {
    return null;
  }
};

export const saveOrder = async (order) => {
  if (order.length === 0) {
    return;
  }
  if (!auth.currentUser) {
    window.location.href = "/login";
    return;
  }
  try {
    const user = auth.currentUser;
    const userDoc = await getUser(user.uid);
    let total = 0;
    let orderQuantity = 0;
    order.forEach((item) => {
      total += item.product.price * item.quantity;
      orderQuantity += item.quantity;
    });
    if (user) {
      //add the order to the orders collection
      const docRef = await addDoc(collection(db, "orders"), {
        items: order,
        total: total,
        date: new Date(),
        user: user.uid,
        email: user.email,
      });
      const orderId = docRef.id;
      //update the quantity of the item's stock
      order.forEach(async (item) => {
        const product = await getProduct(item.product.id);
        const newStock = product.stock - item.quantity;
        await setDoc(
          doc(db, "products", item.product.id),
          {
            stock: newStock,
          },
          { merge: true }
        );
      });
      //add the order to the user's orders array
      await setDoc(doc(db, "users", user.uid), {
        ...userDoc,
        orders: [...userDoc.orders, orderId],
      });
      //update the item-specific analytics
      order.forEach(async (item) => {
        const size = item.size;
        await setDoc(
          doc(db, "analytics", item.product.id),
          {
            numOrders: increment(1),
            quantity: increment(item.quantity),
            [size]: increment(item.quantity),
            image: item.product.image,
            name: item.product.name,
          },
          { merge: true }
        );
      });
      //update the summary analytics
      await setDoc(
        doc(db, "analytics", "summary"),
        {
          orderNum: increment(1),
          total: increment(total),
          quantityNum: increment(orderQuantity),
        },
        { merge: true }
      );
    }
  } catch (error) {
    console.error(error);
  }
};

export const getAnalytics = async () => {
  try {
    const summary = await getDoc(doc(db, "analytics", "summary"));
    const summaryData = summary.data();

    const snapshot = await getDocs(collection(db, "analytics"));
    let analyticsArray = [];
    snapshot.forEach((doc) => {
      if (doc.id !== "summary") {
        const data = doc.data();
        const analytics = { id: doc.id, ...data };
        analyticsArray.push(analytics);
      }
    });

    return { summary: summaryData, analytics: analyticsArray };
  } catch (error) {
    console.error(error);
  }
};

export const getFeaturedProducts = async () => {
  //gets the first 5 products in the products collection, will add more logic later
  try {
    let productsArray = [];
    const snapshot = await getDocs(collection(db, "products"), limit(5));
    snapshot.forEach((doc) => {
      const data = doc.data();
      const product = { id: doc.id, image: data.image };
      productsArray.push(product);
    });
    return productsArray;
  } catch (error) {
    console.error(error);
  }
};

export const updateItem = async (id, data) => {
  try {
    await setDoc(doc(db, "products", id), data);
  } catch (error) {
    console.error(error);
  }
};

export const deleteItem = async (id) => {
  try {
    await deleteDoc(doc(db, "products", id));
  } catch (error) {
    console.error(error);
  }
};

export const createItem = async () => {
  try {
    const docRef = await addDoc(collection(db, "products"), {
      name: "New Item",
      image: "",
      price: 0,
      sizes: [],
      color: "",
      description: "",
      category: "",
      images: [],
    });
    return docRef.id;
  } catch (error) {
    console.error(error);
  }
};

export const getDiscountCodes = async () => {
  try {
    let codesArray = [];
    const snapshot = await getDocs(collection(db, "discounts"));
    snapshot.forEach((doc) => {
      const data = doc.data();
      const code = { code: doc.id, ...data };
      codesArray.push(code);
    });
    return codesArray;
  } catch (error) {
    console.error(error);
  }
};

export const checkStock = async (product) => {
  try {
    await getDoc(doc(db, "products", product.id)).then((doc) => {
      if (doc.exists()) {
        const data = doc.data();
        return data.stock > product.quantity;
      }
      return false;
    });
  } catch (error) {
    console.error(error);
    return false;
  }
};
