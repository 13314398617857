import React, { useState } from "react";
import "../styles/checkout.css";
import "../styles/itemUpdate.css";
import { IoClose, IoCheckmark } from "react-icons/io5";
import { updateItem, deleteItem } from "../helpers/firestore";
import { uploadImage } from "../helpers/storage";
import { Draggable } from "react-drag-reorder";

export default function ItemUpdateForm({ item, updateEditing }) {
  const [name, setName] = useState(item.name);
  const [image, setImage] = useState(item.image);
  const [price, setPrice] = useState(item.price);
  const [sizes, setSizes] = useState(item.sizes ?? []);
  const [color, setColor] = useState(item.color);
  const [description, setDescription] = useState(item.description);
  const [category, setCategory] = useState(item.category);
  const [imageFile, setImageFile] = useState(null);
  const [images, setImages] = useState([...(item.images ?? [])]);
  const [uploading, setUploading] = useState(false);
  const sizeOptions = ["XS", "S", "M", "L", "XL", "XXL"];
  const colorChoices = [
    {
      name: "black",
      value: "#000000",
      border: "1px solid #000000",
      color: "#ffffff",
    },
    {
      name: "white",
      value: "#FFFFFF",
      border: "1px solid #444444",
      color: "#000000",
    },
    {
      name: "navy",
      value: "#000080",
      border: "1px solid #000080",
      color: "#ffffff",
    },
    {
      name: "off-white",
      value: "#F5F5DC",
      border: "1px solid #F5F5DC",
      color: "#000000",
    },
    {
      name: "light blue",
      value: "#8EC8EC",
      border: "1px solid #8EC8EC",
      color: "#ffffff",
    },
    {
      name: "other",
      value: "#8d429f",
      border: "1px solid #444444",
      color: "#ffffff",
    },
  ];

  const handleClose = () => {
    updateEditing(null);
  };

  const handleImageChange = (event) => {
    setUploading(true);
    setImageFile(event.target.files[0]);
    uploadImage(event.target.files[0], item, images.length).then((url) => {
      setUploading(false);
      setImages([...images, url]);
    });
  };

  const changeImageOrder = (currentPos, newPos) => {
    const newImages = [...images];
    const [removed] = newImages.splice(currentPos, 1);
    newImages.splice(newPos, 0, removed);
    setImages(newImages);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    updateItem(item.id, {
      name,
      image,
      price,
      sizes,
      color,
      description,
      category,
      images,
    });
    handleClose();
  };

  React.useEffect(() => {
    if (images.length > 0 && images[0] !== image) {
      setImage(images[0]);
    }
  }, [images]);

  return (
    <div className="checkoutPage itemUpdatePage">
      <form className="checkoutDrawer itemUpdateForm" onSubmit={handleSubmit}>
        <div className="itemUpdateFormContent">
          <div className="itemUpdateFormImage">
            {image && (
              <img
                src={`https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Ffull%2F${image}_1728x2160?alt=media`}
                alt={name}
                id="itemUpdateMainImage"
              />
            )}
            <div className="itemUpdateFormImages">
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              {images.length > 0 && (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Draggable onPosChange={changeImageOrder}>
                    {images.map((img, index) => (
                      <img
                        key={index}
                        src={`https://firebasestorage.googleapis.com/v0/b/naimun-merch.appspot.com/o/products%2Fthumbnails%2F${img}_200x200?alt=media`}
                        alt={name}
                        className="itemUpdateThumbnail"
                      />
                    ))}
                  </Draggable>
                </div>
              )}
            </div>
          </div>
          <div className="itemUpdateFormDetails">
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              {colorChoices.map((colorChoice) => (
                <div
                  key={colorChoice.name}
                  className="colorChoice"
                  style={{ flex: 1 }}
                  onClick={() => {
                    setColor(colorChoice.name);
                  }}
                >
                  <div
                    className="colorChoiceCircle"
                    style={{
                      backgroundColor: colorChoice.value,
                      border: colorChoice.border,
                      color: colorChoice.color,
                    }}
                  >
                    {color === colorChoice.name && <IoCheckmark />}
                  </div>
                  {colorChoice.name}
                </div>
              ))}
            </div>
            <input
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <input
              type="text"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            />
            <div
              className="productPageSizeOptions"
              style={{ fontWeight: "bold", marginBottom: "10px" }}
            >
              {sizeOptions.map((size) => (
                <div
                  className={`productPageSizeOption ${
                    sizes.includes(size) && "productPageSizeSelected"
                  }`}
                  key={size}
                  onClick={() =>
                    setSizes(
                      sizes.includes(size)
                        ? sizes.filter((s) => s !== size)
                        : [...sizes, size]
                    )
                  }
                >
                  {size}
                </div>
              ))}
            </div>
          </div>
          <button type="submit">Update Item</button>
          <button
            onClick={() => deleteItem(item.id)}
            disabled={uploading}
            style={{ marginTop: "10px", backgroundColor: "#d64747" }}
          >
            Delete Item
          </button>
        </div>
      </form>
      <div className="itemUpdateClose" onClick={handleClose}>
        <IoClose className="closeIcon" />
      </div>
    </div>
  );
}
