import { analytics } from "./firebase";
import { logEvent } from "firebase/analytics";

export const logCartAdd = (item) => {
  logEvent(analytics, "add_to_cart", {
    item_id: item.id,
    item_name: item.name,
    item_category: item.category,
    price: item.price,
  });
};

export const checkoutStart = (items) => {
  logEvent(analytics, "begin_checkout", {
    items: items.map((item) => ({
      item_id: item.id,
      item_name: item.name,
      item_category: item.category,
      price: item.price,
    })),
  });
};

export const loginEvent = () => {
  logEvent(analytics, "login");
};

export const signUpEvent = () => {
  logEvent(analytics, "sign_up");
};

export const viewItem = (item) => {
  logEvent(analytics, "view_item", {
    item_id: item.id,
    item_name: item.name,
    item_category: item.category,
    price: item.price,
  });
};

export const logPurchase = (items, total) => {
  logEvent(analytics, "purchase", {
    value: total,
    currency: "USD",
    items: items,
  });
};

export const logAddShippingInfo = (items, total) => {
  logEvent(analytics, "add_shipping_info", {
    value: total,
    currency: "USD",
    items: items,
  });
  console.log("Shipping Info Added");
};

export const logAddPaymentInfo = (items, total) => {
  logEvent(analytics, "add_payment_info", {
    value: total,
    currency: "USD",
    items: items,
  });
  console.log("Payment Info Added");
};
